import * as React from 'react';

import { Text, BlogImage, BlogLink } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import speechTherapistAndKids from '../../../images/educational-corner/blog24/Logopedi-i-djeca.jpg';
import pirateGymnastics1 from '../../../images/educational-corner/blog25/gusarska-gimnastika-1.jpg';
import pirateGymnastics2 from '../../../images/educational-corner/blog25/gusarska-gimnastika-2.jpg';
import guessCorrectVoice from '../../../images/educational-corner/blog25/pogodi-tocan-glas.jpg';

const BigIssueYouHaventHeardAbout = () => {
  return (
    <PageNew
      title="KOKOLINGO - Veliki problem za koji sigurno niste čuli"
      description="Novo okruženje, socijalizacija, predznanje, preteške torbe, 
      siguran put do škole,… O poremećajima izgovora rijetko ćemo čuti kada 
      je riječ o najčešćim temama vezanim uz djecu predškolske dobi i 
      polasku u školu. Je li to baš toliko važno, zapitat će se mnogi. 
      Ako dijete i ne može izgovoriti neki glas, već će ga „uhvatiti“, 
      prije ili kasnije. Možda ćete se ipak predomisliti kada vam kažemo: Za lačunanje i 
      pisanje možda nije potlebno l, ali je važno za čitanje, 
      plepličavanje, pličanje i dluženje."
      headline="Veliki problem za koji sigurno niste čuli"
    >
      <Text as="h2">Je li to baš toliko važno?</Text>
      <Text as="p">
        Novo okruženje, socijalizacija, predznanje, preteške torbe, siguran put
        do škole,… O poremećajima izgovora rijetko ćemo čuti kada je riječ o
        najčešćim temama vezanim uz djecu predškolske dobi i polasku u školu. Je
        li to baš toliko važno, zapitat će se mnogi. Ako dijete i ne može
        izgovoriti neki glas, već će ga „uhvatiti“, prije ili kasnije. Možda
        ćete se ipak predomisliti kada vam kažemo: Za lačunanje i pisanje možda
        nije potlebno l, ali je važno za čitanje, plepličavanje, pličanje i
        dluženje. Od jezičnih vještina, učenja, do društvenih vještina, izgovor
        je važan posvuda, a za dijete je on vjerojatno najosjetljivija tema na
        području društvenih vještina i socijalizacije.
      </Text>
      <Text as="p">
        Nije jednostavno naviknuti se na nove zadatke, raspored i obaveze
        dolaskom u školu. Opušteniju obiteljsku ili vrtićku odgojno-obrazovnu
        sredinu treba zamijeniti posve novom i drugačijom. Dijete se treba
        naviknuti na dulju koncentraciju i pažljivo slušanje, svakodnevne zadaće
        i školske obaveze. U savladavanju novih gradiva i izvršavanju zadataka
        naš nam je jezik najpotrebniji alat, a kada s njime imamo poteškoća, svi
        se zadaci doimaju puno zahtjevnijima i težima.
      </Text>
      <Text as="p">
        Osim ovog vidljivog problema, puno je veći onaj nevidljivi. Dolazak u
        novu sredinu i novi izazovi puno su lakši kada nisu opterećeni dodatnim
        poteškoćama, poput problema s pravilnim izgovorom. To može značajno
        utjecati na samopouzdanje djeteta, spremnost na socijalizaciju u novoj
        sredini i suočavanje sa zadacima koji su pred njime. Pri komunikaciji sa
        svojim vršnjacima dijete može osjećati sram, može se osjećati manje
        vrijedno ili sposobno i teže sklapati nova poznanstva i prijateljstva.
        Suočeno s novim školskim zadacima, može osjećati puno veći pritisak pred
        obavezama koje treba izvršiti. Naposljetku, svakom je novom izazovu
        lakše pristupiti kada su uz nas poznati elementi sigurnosti i svaki je
        novi problem lakši kada nije dodatno opterećen već postojećim
        poteškoćama.
      </Text>
      <Text as="p">
        Zato bi svakom djetetu trebalo pomoći da brzo i uspješno prevlada
        poteškoće s pravilnim izgovorom, kao i da se time počne baviti što
        ranije, ne čekajući da se nekoliko puta uvjerimo kako se problem ipak
        neće s vremenom riješiti sam od sebe. Svako dijete zaslužuje ranu
        intervenciju, što manje stresa pri prelasku u školske klupe, ali i, kada
        god je to moguće, što ugodnije vrtićke dane obilježene potpunom slobodom
        izražavanja.
      </Text>
      <Text as="h2">Svako četvrto dijete treba pomoć</Text>
      <Text as="p">
        Sigurno ćete se iznenaditi kada čujete da je učestalost poremećaja
        izgovora glasova u predškolskoj populaciji djece između 20 i 30%. Većina
        nas letimice će prijeći očima preko ovih brojki, i premda dobro znamo
        matematiku, nećemo previše razmišljati da to u stvarnom životu u
        prosjeku predstavlja svako četvrto stvarno dijete koje se još uvijek
        muči s izgovorom nekoga glasa prije polaska u školu.
      </Text>
      <BlogImage
        imgSrc={pirateGymnastics1}
        imgAlt="Dijete vjezba oblike usta za laksi izgovor rijeci"
        imgTitle="Dijete vjezba oblike usta za laksi izgovor rijeci"
      />
      <Text as="p">
        Poremećaji izgovora mogu biti artikulacijski (zbog nepravilnog položaja
        ili oštećenja govornih organa) ili fonološki (gdje izgovor ovisi o
        položaju glasa u riječi ili o susjednim glasovima), kao i kombinacija
        ovih dvaju poremećaja. Pri nepravilnom izgovoru, dijete tako
        problematičan glas može preskakati, zamjenjivati nekim drugim ili krivo
        izgovarati. Artikulacijski poremećaji mogu se javljati samostalno, ali i
        uz neke druge poteškoće – disleksiju, apraksiju, dispraksiju,
        intelektualne teškoće, oštećenja sluha i drugo.
      </Text>
      <Text as="p">
        Prema Državnom zavodu za statistiku, u Hrvatskoj je 37537
        četverogodišnjaka s artikulacijskim poremećajima, 37503 petogodišnjaka
        te 39566 šestogodišnjaka, a više od 30% predškolske djece u dobi između
        treće i šeste godine treba logopeda.
      </Text>
      <Text as="p">
        Uza sve navedene brojke, kako je moguće da je o ovako učestaloj pojavi
        javnost tako malo osviještena? Kako to da se ovoj temi, koja je za djecu
        toliko delikatna i tako važna, ne pridaje takva važnost kao mnogima
        drugima? Ako se svaki četvrti predškolac među našom djecom muči s
        pravilnim izgovorom jednog ili više glasova, dužni smo o tome više
        pričati, više se informirati, upoznavati javnost s problemima kada god
        je to moguće. Potrebno je o tome govoriti u obiteljima, u vrtićima, u
        školama, apelirati da se poveća broj stručnjaka koji rade s djecom.
      </Text>
      <BlogImage
        imgSrc={pirateGymnastics2}
        imgAlt="Dijete vjezba oblike usta za laksi izgovor rijeci"
        imgTitle="Dijete vjezba oblike usta za laksi izgovor rijeci"
      />
      <Text as="h2">Odlična iskustva s kokolingom</Text>
      <Text as="p">
        Otkada je na tržište stigla digitalna vježbenica za korekciju
        artikulacijskih poremećaja <BlogLink>Kokolingo</BlogLink>, dobili smo
        puno povratnih informacija roditelja i djece i vodili mnoge zanimljive
        razgovore s njima.
      </Text>
      <Text as="p">
        Gotovo redovito roditelji ističu veću dostupnost logopedske terapije,
        pogotovo u manjim mjestima, gdje je do nje teže doći. Ovdje je jako
        važno napomenuti da je vježbenicu potrebno koristiti pod nadzorom
        logopeda na terapiji ili prema njegovim uputama u radu s roditeljima kod
        kuće.
      </Text>
      <Text as="p">
        Najčešće povratne informacije roditelja su i da je{' '}
        <BlogLink>Kokolingo</BlogLink> učinio vježbanje kod kuće puno
        zanimljivijim. Do sada se to svodilo na pomalo suhoparno ponavljanje
        dugih lista riječi, a sada se pretvorilo u šarenu računalnu igricu u
        kojoj se skupljaju bodovi i veseli se svakoj novoj razini. Roditelji nam
        kažu da često ne mogu uspostaviti suradnju s djecom, dok su papigica
        Koko i njezin prijatelj gusar Srećko jako zanimljivi i uvijek ih se
        sluša. Poseban je poticaj i ohrabrenje papigice Koko koja svako toliko
        preleti preko ekrana, pohvali djecu za trud i potakne ih da i dalje
        marljivo rade.
      </Text>
      <Text as="p">
        Zanimljiva je i priča jedne mame koja nam je rekla da ni sama ne može
        izgovoriti glas r, a time ni vježbati izgovor tog glasa s djetetom kod
        kuće te joj je ova aplikacija jako dobro došla, jer izgovara riječi
        umjesto nje. Kao i jednog tate koji ima poteškoća s izgovorom jednoga
        glasa i odlučio je i sam, premda kaže da je „prevelik za to“, upustiti
        se u avanturu i poigrati se sa Srećkom i Koko.
      </Text>
      <Text as="p">
        U korekciju nepravilnog izgovora često nije uključeno samo jedno dijete
        u obitelji. U nekim obiteljima uz <BlogLink>Kokolingo</BlogLink> je
        vježbalo i dvoje djece, svako već prema tome koji mu glas treba, a bilo
        nam je jako drago čuti i o starijoj braći i sestrama koji su vježbali s
        mlađima i tako ih motivirali i pomagali im.
      </Text>
      <Text as="p">
        Vježbanje uz pomoć <BlogLink>Kokolinga</BlogLink> uvijek ide paralelno s
        logopedskom terapijom i posebno nam je drago čuti roditelje kada nam
        pričaju kako je korištenje vježbenice u nekim slučajevima poboljšalo
        učinkovitost vježbanja i ubrzalo terapiju. To nije teško shvatiti, s
        obzirom na to da ovaj novi digitalni alat može donijeti novu dinamiku i
        zanimljivost u radu s izgovorom. Međutim, uvijek treba imati na umu da
        će vam putokaz u taj šareni svijet dati stručnjak, a roditelji će se
        pobrinuti da uvijek budu vodič i podrška djeci u radu.
      </Text>
      <Text as="p">
        Vježbenica <BlogLink>Kokolingo</BlogLink> često bi poslužila i malo
        izvan namijenjenih okvira ispravljanja artikulacijskih poremećaja te je
        pomagala djeci da se lakše otvore i komuniciraju, da budu manje
        sramežljiva nego na terapijama te spremnije ponavljaju riječi i
        rečenice. Tako su je koristila i djeca s poremećajima iz spektra autizma
        te djeca usporenog jezičnog razvoja. Ovdje treba reći da to nije prvotna
        namjena ove vježbenice, ali su je korisnici samoinicijativno isprobali i
        na tom području kako bi se obogaćivao rječnik, ubrzao proces usvajanja
        jezika te jačalo samopouzdanje.
      </Text>
      <BlogImage
        imgSrc={guessCorrectVoice}
        imgAlt="Slika prikazuje igru Potraga za blagom gdje dijete mora pogoditi koji se glas spominje u slici"
        imgTitle="Potraga za blagom"
      />
      <Text as="p">
        Nadamo se da će i popularnost aplikacije <BlogLink>Kokolingo</BlogLink>{' '}
        dati značajan doprinos osvješćivanju javnosti o artikulacijskim
        poremećajima i njihovoj visokoj učestalosti među djecom. Sve kako bi se
        malo-pomalo broj osposobljenih stručnjaka povećao, terapije postale
        dostupnijima, a potreba za njima bila prepoznata čim ranije..
      </Text>
      <BlogImage
        imgSrc={speechTherapistAndKids}
        imgAlt="Djeca vjezbaju sa logopedom"
        imgTitle="Djeca vjezbaju sa logopedom"
      />
      <SectionBlog />
    </PageNew>
  );
};

export default BigIssueYouHaventHeardAbout;
